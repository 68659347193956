<template>
    <div class="main">
        <div class="header-container">
            <div class="logo-container">
                <img src="./../assets/images/backbone-logo.png" alt="logo BACKBONE" />
            </div>
            <div class="logo-sep"></div>
            <div class="title-container">
                <h1>Webinar</h1>
            </div>
        </div>
        <h1 class="text-subtitle">Login</h1>
        <div class="sep-subtitle"></div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(login)" >
                <ValidationProvider  class="input-login"  rules="required|max:100|email" v-slot="{ errors }">
                    <label>Email address</label>
                    <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="email" />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider  class="input-login"  rules="required|max:100" v-slot="{ errors }">
                    <label>Password</label>
                    <input type="password" :class="{ 'input-error' : errors[0]}"  v-model="pwd" />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <input type="submit" class="btn-form" value="LOGIN" />
                <div class="form-failed" v-if="error" v-html="errorMsg"></div>
            </form>
        </ValidationObserver>
        <router-link class="pwd-lost" to="/password-lost">Password lost? </router-link>
        <div class="create-account">
            <p>Don’t have an account yet?</p>
            <router-link to="/create-account" class="btn-form">REGISTER</router-link>
        </div>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import axios from 'axios'
import store from './../store'

export default {
    name : 'Login',
     components : {
        ValidationProvider, ValidationObserver
    },
    data() {
        return {
            email : null,
            pwd: null,
            error : false,
            errorMsg : null,
        }
    },
    methods : {
        login() {
            this.error = false
            this.errorMsg = null
            let vm = this

            axios
                .get(store.getters.getURL + "user/login.php?email=" + this.email + '&pwd=' + this.pwd )
                .then(function (response) { 
                    if(response.data.state == 'success') {
                        let user = {
                            type : response.data.user_type,
                            id : response.data.user_id,
                            token : response.data.token
                        }
                        vm.$store.commit('login', user)

                        /* redirection */
                        if(user.type == 1) {
                            vm.$router.push('/webinar-list')
                        }else if(user.type == 2) {
                            if(store.state.routerHistory ) {
                                vm.$router.push('/speaker/webinar-answers/' + store.state.routerHistory)
                            }else {
                                vm.$router.push('/speaker/webinar-list')
                            }
                        }else if(user.type == 3) {
                            vm.$router.push('/admin/webinar-list')
                        }
                    }else {
                        vm.error = true
                        vm.errorMsg = response.data.retour
                    }
                })
                .catch((err) => {
                    this.error = true
                    this.errorMsg = "An error has occured: " +  err
                });
        }
    },
    mounted() {
        let auth = this.$store.getters.getAuth
        if(auth.is_loged && auth.type == 1) {
            this.$router.push('/webinar-list')
        }
        if(auth.is_loged && auth.type == 2) {
            this.$router.push('/speaker/webinar-list')
        }
        if(auth.is_loged && auth.type == 3) {
            this.$router.push('/admin/webinar-list')
        }
    }

}
</script>

<style lang="scss" scoped>

    .pwd-lost {
        color:inherit;
        text-decoration: none;
        margin-top:20px;
        font-size:13px;
    }

    .pwd-lost:hover {
        text-decoration: underline;
    }

    .create-account {
        margin-top:40px;
    }

    .create-account a {
        text-decoration: none;
        margin:0 auto;
        font-size:13.3px;
    }

    .create-account p {
        margin-bottom:5px;
        color:$secondaryColor;
        font-size:18px;
        text-align: center;
    }
</style>